<template>
  <div class="hello">
  <div>{{ data }}</div>
  </div>
</template>

<script>
// import {MobileDetect} from "./jsjs"
const axios = require("axios");

export default {
  name: 'HelloWorld',
  data(){
    return {
      data:""
    }
  },
  mounted(){
    // alert(window.document.location.pathname)
    // console.log(window.navigator.userAgent)
    var params = {
      param:window.document.location.pathname,
      data:String(window.navigator.userAgent)
    };
    var headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        };
    axios.post("/api/get",params,headers).then((res)=>{
      console.log(res.data)
      // alert(res.data.toString())
      this.data = res.data.data
    })
    console.log(this.data)
    // alert(this.data)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
